/**
 * Sends a JSONP request.
 * @param {Object} params - The parameters for the JSONP request.
 * @param {string} params.url - The URL to which the request is sent.
 * @param {string} [params.callbackName=defaultCallback] - The name of the callback function to be invoked.
 * @param {Object} [params.data={}] - The data to be sent with the request.
 * @param {function} [params.success=defaultSuccess] - The function to be called if the request succeeds.
 * @param {function} [params.error=defaultError] - The function to be called if the request fails.
 * @param {number} [params.timeout=30000] - The time in milliseconds to wait before considering the request as failed.
 */
export default function sendJsonpRequest({
    url,
    callbackName = "defaultCallback",
    data = {},
    success = () => {},
    error = () => {},
    timeout = 30000,
}) {
    // Create a script element
    const script = document.createElement("script");
    const head = document.head || document.getElementsByTagName("head")[0];

    // Generate a unique callback function name
    const callbackFunctionName = `jsonp_${callbackName}_${Date.now()}`;

    // Append the callback function to the global window object
    window[callbackFunctionName] = (response) => {
        // Clean up the script element and callback function after the response is received
        head.removeChild(script);
        clearTimeout(script.timer);
        delete window[callbackFunctionName];

        // Call the success function with the response
        success(response);
    };

    // Add the callback function name to the data
    data.jsoncallback = callbackFunctionName;

    // Format the data as query string parameters
    const queryString = Object.keys(data)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");

    // Set the script's src attribute to the URL with query string
    script.src = `${url}?${queryString}`;

    // Append the script element to the head to initiate the request
    head.appendChild(script);

    // Set up a timeout to handle request failures
    script.timer = setTimeout(() => {
        // Clean up the script element and callback function on timeout
        delete window[callbackFunctionName];
        head.removeChild(script);

        // Call the error function if provided
        error({ message: "Request timed out" });
    }, timeout);
}
