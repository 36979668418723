<template>
  <div class="management">
    <div class="banner">
        <img class="w100" src="https://static2.51fanli.net/webapp/images/app/corp/home/management_team.png" alt="business" />
    </div>
    <div class="managers">
      <div class="manager-item" v-for="(item, index) in personList" :key="index">
        <div class="item-w1200">
          <img :src="item.picture" class="manager-pic">
          <div class="introduce">
            <p class="name">{{item.name}}</p>
            <p class="info" v-for="(Itm, Idx) in item.introduce" :key="Idx">{{Itm}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Management',
  components: {
    
  },
  data() {
    return {
      personList: [
        {picture: 'https://static2.51fanli.net/webapp/images/app/corp/home/gary.png', name: '葛永昌', introduce: ['返利网数字科技股份有限公司 董事长、总经理', '返利网创始人。毕业于同济大学，返利网中欧国际工商管理学院创业营学员，上海市青联委员。', '获上海市领军人才、上海市IT青年新锐、上海十大互联网创业家等荣誉称号。']},
        {picture: 'https://static2.51fanli.net/webapp/images/app/corp/home/luqi.png', name: '卢岐', introduce: ['返利网数字科技股份有限公司 副董事长', '中国国籍，男，1980年8月出生，中共党员，经济学博士研究生。历任北京市人民政府办公厅秘书、北京市政路桥集团有限公司办公室副主任（挂职）、北京市人民政策办公厅副处长、处长、江西昌九生物化工股份有限公司总经理、董事长。现任北京市文化中心建设发展基金管理有限公司书记、总经理、返利网数字科技股份有限公司董事、副董事长。']},
        {picture: 'https://static2.51fanli.net/webapp/images/app/corp/home/iris.png', name: '隗元元', introduce: ['返利网数字科技股份有限公司 董事、副总经理', '返利网联合创始人，毕业于东华大学视觉传达专业、中欧国际工商学院EMBA硕士学位。']},
        {picture: 'https://static2.51fanli.net/webapp/images/app/corp/home/james.png', name: '朱旻', introduce: ['返利网数字科技股份有限公司 董事、副总经理', '返利网联合创始人，毕业于加利福尼亚大学计算机科学与工程专业双学士学位、清华大学EMBA硕士学位。曾任职Corel中国区执行总裁。白玉兰纪念奖获得者，上海市优秀软件企业家。']},
        // {picture: 'https://static2.51fanli.net/webapp/images/app/corp/home/feikaiwen.png', name: '费岂文', introduce: ['返利网数字科技股份有限公司 财务总监', '澳大利亚拉筹伯大学工商管理硕士。历任上海会计师事务所注册会计师，福建莆田湄洲湾电厂财务助理总监，德利多富信息系统（上海）有限公司财务助理总监，美银宝信息技术（上海）有限公司财务总监，上海亿贝网络信息服务有限公司财务总监，孚阁科技（上海）有限公司总经理、执行董事。']},
        {picture: 'https://static2.51fanli.net/webapp/images/app/corp/home/chenming.png', name: '陈明', introduce: ['返利网数字科技股份有限公司 董事会秘书', '毕业于中国政法大学。曾任职于中国人民武装警察部队某部、北京市文化中心建设发展基金管理有限公司、江西昌九生物化工股份有限公司。现任返利网数字科技股份有限公司董事会秘书。']},
        {picture: 'https://static2.51fanli.net/webapp/images/app/corp/home/yinshuang.jpg', name: '尹爽', introduce: ['返利网数字科技股份有限公司 战略执行委员会秘书长', '浙江理工大学应用心理学硕士学位。历任公司生态事业部总经理、上海中彦信息科技有限公司产品副总裁，现任公司战略执行委员会秘书长、上海伊昶信息科技有限公司执行董事兼总经理。']},
      ]
    }
  },
}
</script>

<style lang="less" scoped>
.management {
  .banner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .w100 {
      width: 100%;
    }
  }

  .managers {
    margin-top: 13px;

    .manager-item {
      padding: 60px 0;

      .item-w1200 {
        margin: 0 auto;
        display: inline-flex;
        align-items: center;
      }

      .manager-pic {
        width: 280px;
        margin-right: 84px;
      }

      .introduce {
        width: 836px;
        text-align: left;

        .name {
          font-size: 32px;
          color: #333333;
          margin-bottom: 16px;
        }

        .info {
          font-size: 18px;
          color: #8A8A8A;
          line-height: 28px;
          font-weight: 200;
        }
      }
    }
    .manager-item:nth-of-type(even) {
      background: #F5F5F7;
    }
  }
}
</style>
