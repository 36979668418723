<template>
  <div class="home">
    <swiper ref="mySwiper" :options="swiperOptions" class="swiper-cus">
      <swiper-slide v-for="(item, index) in bannerList" :key="index">
        <img :src="item" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <!-- 公司介绍 -->
    <div class="company-introduction">
      <h4 class="company-name">返利科技</h4>
      <div class="introduce">
        <p>返利网数字科技股份有限公司创立于2007年，总部位于上海，并在北京、广州等多地设有分支机构，于2021年4月登陆上海证券交易所A股主板上市（股票代码600228）。</p>
        <br />
        <p>公司秉承“万物皆可返利”理念，致力为用户的互联网消费创造惊喜，让优惠触手可及。基于深耕消费行业的优势经验，返利科技为客户提供电商平台促销、优惠特权整合供应、电商一站式直播代运营服务及全域整合营销等核心服务，通过促进用户活跃转化，助力客户实现流量商业化变现，降本增效地完成业务商业化闭环，服务数字消费升级，赋能数字消费生态。</p>
        <br />
        <p>公司旗下拥有国内领先的导购平台返利网及返利APP，基于“为美好生活而省”的初心，为消费者提供360°全景式消费优惠服务。据艾瑞咨询报告，“返利网APP”位列同类移动应用总使用次数No.1*、月平均活跃用户数No.1*。</p>
        <br />
        <p>公司旗下整合营销业务，以品效联动、品效合一为宗旨，致力于为品牌及商家提供标准化或定制化的整合数字营销方案。返利科技曾荣获第11届金鼠标数字营销大赛奖项。</p>
        <br />
        <p>公司旗下橘脉传媒业务，以短视频、直播为媒介，通过直播电商一站式代运营，链接全生态矩阵，触达万千消费，支持客户品牌及销量同步增长。</p>
        <br />
        <p>目前，返利科技已与国内超过400家商城&平台、逾5万家品牌商户合作，涵盖了全球知名电商，包括天猫、淘宝、京东、苹果官网等知名平台，同时亦和美团、饿了么等生活服务平台开展合作。</p>
        <br />
        <p class="small">*根据艾瑞咨询《2020年中国在线导购平台行业研究报告》2019年3月-2020年6月“中国在线导购行业”第三方电商导购类应用相关数据。</p>

        
      </div>
    </div>
    <!-- 100%图片 -->
    <img src="https://static2.51fanli.net/webapp/images/app/corp/home/home_title_business.jpg" class="w100">
    <!-- 部门 -->
    <div class="company-department">
      <div class="division-list">
        <span :class="index == activeIndex ? 'active' : ''" v-for="(item, index) in divisionList" :key="index" @click="chooseSwiperItem(index)">{{item.name}}</span>
      </div>
      <!-- <img class="division-image" :src="divisionList[activeIndex].img"> -->
      <swiper ref="swiperDivision" :options="swiperOptionsDivision" class="division-image"  @slideChangeTransitionEnd="changeItem">
        <swiper-slide v-for="(item, index) in divisionList" :key="index">
          <img :src="item.img" />
          <a href="http://mtx.net.cn/#/index" class="hot-link" v-if="item.type == 'mtx'"></a>
        </swiper-slide>
      </swiper>
      <div class="company-technology">
        <h4 class="title">前沿技术实力</h4>
        <div class="content">
          <div class="technology-item" v-for="(item, index) in technologyList" :key="index">
            <img :src="item.icon" class="item-icon">
            <div class="item-info">
              <p class="info-title">{{item.title}}</p>
              <p class="info-msg" v-for="(Itm, idx) in item.explain" :key="idx">{{Itm}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="https://static2.51fanli.net/webapp/images/app/corp/home/home_title_serve.png" class="w100">
    <!-- 服务客户 -->
    <div class="partners">
      <p class="title">与国内超过400家商城&平台、逾5万家品牌商户合作，覆盖百万家本地生活线下</p>
      <img src="https://static2.51fanli.net/webapp/images/app/corp/home/home_market_logo.png" class="w100">
      <div class="contact">
        <p>商务合作联系：<a href="mailto:AD@fanli.com">AD@fanli.com</a></p>
        <p>媒体合作联系：<a href="mailto:PR@fanli.com">PR@fanli.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        autoplay: {
          delay: 3000,
        },
        loop: true,
      },
      swiperOptionsDivision: {
        loop: true,
      },
      bannerList: [
        'https://static2.51fanli.net/webapp/images/app/corp/home/homeTopBanner.png',
        'https://static2.51fanli.net/webapp/images/app/corp/home/home_banner1.png', 
        'https://static2.51fanli.net/webapp/images/app/corp/home/home_banner4.png',
        'https://static2.51fanli.net/webapp/images/app/corp/home/home_banner2.png',
        'https://static2.51fanli.net/webapp/images/app/corp/home/home_banner3.png',
      ],
      divisionList: [
        {name: '返利网及返利APP', img: 'https://static2.51fanli.net/webapp/images/app/corp/home/flw_app3.jpg'},
        {name: '整合营销', img: 'https://static2.51fanli.net/webapp/images/app/corp/home/qyyx_system2.jpg'},
        // {name: '满天星', img: 'https://static2.51fanli.net/webapp/images/app/corp/home/mtx1.png', type: 'mtx'},
        {name: '橘脉传媒', img: 'https://static2.51fanli.net/webapp/images/app/corp/home/jmcm4.jpg'},
        // {name: '返利生态SaaS', img: 'https://static2.51fanli.net/webapp/images/app/corp/home/flst_sass.png'},
        // {name: '权益 会员卡', img: 'https://static2.51fanli.net/webapp/images/app/corp/home/qyhyk.png'},
      ],
      technologyList: [
        {icon: 'https://static2.51fanli.net/webapp/images/app/corp/home/icon1.png', title: '大数据分析及个性化推荐技术', explain: ['海量数据挖掘技术 实时数据分析平台', '智能数据管理技术 智能推荐引擎', '基于机器学习的智能搜索技术']},
        {icon: 'https://static2.51fanli.net/webapp/images/app/corp/home/icon2.png', title: '移动应用程序开发技术', explain: ['Goshop站外订单跟踪技术', '用户订单与返利发放系统', '查询语义理解技术']},
        {icon: 'https://static2.51fanli.net/webapp/images/app/corp/home/icon3.png', title: '导购服务技术', explain: ['返利网APP分流测试技术', 'HybridAPP研发技术']},
        {icon: 'https://static2.51fanli.net/webapp/images/app/corp/home/icon4.png', title: '广告投放', explain: ['实现更高效灵活地切换投放渠道和策略，提升广告宣传效率']},
      ],
      activeIndex: 0, 
    };
  },
  methods: {
    changeItem() {
      this.activeIndex = this.$refs.swiperDivision.swiper.realIndex;
    },
    chooseSwiperItem(val) {
      this.activeIndex = val;
      this.$refs.swiperDivision.swiper.slideTo(val + 1, 1000, false)
    }
  },
};
</script>
<style lang="less" scoped>
.banner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .w100 {
    width: 100%;
  }
}
.cont {
  font-size: 0;
  img {
    width: 100%;
    object-fit: cover;
  }
}
.swiper-cus {
  width: 100%;
  img {
    width: 100%;
    object-fit: cover;
  }
}
.swiper-pagination {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/ .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    margin: 0 10px;
    background-color: #fff;
    opacity: 1;
  }
  /deep/ .swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    background: none;
  }
}

.company-introduction {
  display: inline-block;
  padding: 65px 0 108px;
  width: 1202px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: left;

  .company-name {
    font-size: 34px;
    color: #363636;
    margin-bottom: 16px;
  }

  .introduce {
    font-size: 18px;
    color: #8A8A8A;
    line-height: 32px;

    .small {
      font-size: 14px;
      color: #8A8A8A;
      letter-spacing: 0.3px;
    }
  }
}

.w100 {
  width: 100%;
}

.company-department {
  display: inline-block;
  width: 1200px;
  margin: 0 auto;
  padding-top: 96px;

  .division-list {
    width: 800px;
    display: flex;
    justify-content: space-around;
    font-size: 24px;
    color: #999999;
    margin-bottom: 30px;

    .active {
      color: #363636;
    }
  }

  .division-image {
    width: 1200px;

    .hot-link {
      position: absolute;
      bottom: 50px;
      left: 50px;
      width: 290px;
      height: 90px;
    }
  }

  .company-technology {
    padding-bottom: 113px;
    
    .title {
      margin-top: 90px;
      text-align: left;
      font-size: 34px;
      color: #363636;
    }
    .content {
      padding-left: 53px;
      display: flex;
      flex-wrap: wrap;

      .technology-item {
        display: inline-flex;
        margin-top: 51px;

        .item-icon {
          width: 65px;
          height: fit-content;
          margin-right: 28px;
        }

        .item-info {
          text-align: left;
          width: 335px;

          .info-title {
            font-size: 24px;
            color: #363636;
            margin-bottom: 14px;
          }

          .info-msg {
            font-size: 18px;
            color: #8A8A8A;
            line-height: 32px;
          }
        }
      }
      .technology-item:nth-of-type(even) {
        margin-left: 93px;
      }
    }
  }
}

.partners {
  padding-top: 115px;
  padding-bottom: 90px;

  .title {
    font-size: 22px;
    color: #8A8A8A;
    line-height: 32px;
    margin-bottom: 50px;
  }

  .contact {
    margin-top: 22px;
    display: flex;
    justify-content: center;
    font-size: 22px;
    color: #8A8A8A;
    line-height: 32px;

    p:nth-of-type(2) {
      margin-left: 30px;
    }

    a {
      color: #8A8A8A;
    }
  }
}
</style>