<template>
  <div class="honour">
    <div class="banner-wrap">
      <img src="https://static2.51fanli.net/webapp/images/app/corp/honor/honor1_banner.png" alt="发展沿革">
    </div>
    <section class="timeline-wrap">
      <ul class="timeline-alternate">
        <li class="timeline-item timeline-item-left">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">至今</div>
            <div class="sub-title">返利科技（股票代码600228）于2021年4月登陆上海证券交易所A股主板上市</div>
            <div class="content">
              <p>荣获国家税务局上海市税务局、上海市科学技术委员会、上海市财政局颁发的：高新技术企业</p>
              <p>荣获京东颁发的：最佳媒体支持奖</p>
            </div>
          </div>
        </li>
        <li class="timeline-item timeline-item-right">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2020</div>
            <div class="content">
              <p>荣获上海报业集团|界面新闻颁发的 :在线新经济（上海）50强</p>
              <p>荣获京东颁发的：最佳媒体支持奖</p>
              <p>荣获网经社电子商务研究中心评选的： 2020电商“战疫”TOP100公司社会责任榜</p>
              <p>荣获TopDigital颁发的 TBI杰出品牌创新奖：2020最具社会责任品牌/2020年度卓越贡献人物奖/2020年度品牌服务奖</p>
              <p>荣获易观颁发的：2020易观之星最佳数字应用奖</p>
              <p>荣获京东颁发的：卓越媒体奖</p>
              <p>荣获京东颁发的：618逐梦者•流量突出贡献奖</p>
              <p>荣获金鼠标组委会颁发的：第11届金鼠标数字营销大赛铜奖</p>
            </div>
          </div>
        </li>
        <li class="timeline-item timeline-item-left">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2019</div>
            <div class="sub-title">注册用户突破2亿 </div>
            <div class="content">
              <p>荣获京东颁发的：11.11阿尔法计划荣耀奖</p>
              <p>荣获通信产业报和赛迪网评选的：互联网50年50佳公益榜</p>
              <p>荣获胡润百富颁发的：2019胡润全球独角兽</p>
              <p>荣获上海市商务委评选的：2018-2019电子商务示范企业、上海市贸易型总部</p>
              <p>荣获软件绿色联盟颁发的： 绿色应用</p>
              <p>荣获上海外事办颁发的：白玉兰纪念奖</p>
            </div>
          </div>
        </li>
        <li class="timeline-item timeline-item-right">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2018</div>
            <div class="content">
              <p>荣获上海传媒传播年会颁发的：品牌助力企业成长奖</p>
              <p>荣获淘宝颁发的：媒体营销峰会突出贡献奖、官方优质合作伙伴</p>
              <p>荣获上海市经济和信息化委员会颁发的：上海市品牌培育示范企业</p>
              <p>荣获创业邦主办评选的：中国硬独角兽TOP100</p>
              <p>荣获国际数字商业创新协会颁发的：商业创意人（葛永昌）</p>
              <p>荣获宜信颁发的：最佳长期合作伙伴奖</p>
            </div>
          </div>
        </li>
        <li class="timeline-item timeline-item-left">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2017</div>
            <div class="sub-title">获兰馨亚洲、新程投资入股 </div>
            <div class="content">
              <p>荣获贵州省政府金融办、贵州证监局、证券时报联合主办颁发的：中国创业企业新苗奖</p>
              <p>荣获360推广评选的：最受关注十大特卖电商</p>
            </div>
          </div>
        </li>
        <li class="timeline-item timeline-item-right">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2016</div>
            <div class="content">
              <p>荣获上海市商务委员会颁发的：上海市电子商务示范企业</p>
              <p>荣获上海科学技术委员会、上海财政局、上海国家税务局、上海地方税务局颁发的：高新技术企业</p>
              <p>荣获上海市软件行业服务协会颁发的：上海市明星软件企业、上海市优秀软件产品</p>
              <p>荣获上海国际信息消费节组委会颁发的：上海国际信息消费节最佳人气奖、上海国际信息消费节优秀企业奖</p>
            </div>
          </div>
        </li>
        <li class="timeline-item timeline-item-left">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2015</div>
            <div class="sub-title">获日本乐天C轮投资 </div>
            <div class="content">
              <p>荣获德勤中国联合品牌中国产业联盟执行主办颁发的：德勤高科技成长中国50强</p>
              <p>荣获中国科学院《互联网周刊》、新华网和中国社会科学院信息化研究中心联合主办评选的：最具创新力互联网公司</p>
              <p>荣获崇明县企业技术中心评选的：崇明县企业技术中心</p>
              <p>荣获阿里巴巴移动事业群评选的：最佳创新应用·PP助手风云榜</p>
              <p>荣获《快公司》中文网主办评选的：《快公司》中国最佳创新公司</p>
              <p>荣获中国科学院《互联网周刊》评选的：《互联网周刊》年度十佳电商APP</p>
            </div>
          </div>
        </li>
        <li class="timeline-item timeline-item-right">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2014</div>
            <div class="sub-title">获海纳亚洲（SIG）B轮投资</div>
          </div>
        </li>
        <li class="timeline-item timeline-item-left">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2013</div>
            <div class="sub-title">获日本乐天C轮投资</div>
            <div class="content">
              <p>荣获清科集团与北京银行联合主办评选的：中国最具投资价值企业50强</p>
              <p>荣获中国电子商务博览会评选的：中国电商服务商百强企业</p>
              <p>荣获上海市高新技术企业认定办公室颁发的：上海市高新技术企业</p>
              <p>荣获上海市高新技术成果转化项目认定办公室颁发的：上海市高新技术成果转化项目</p>
            </div>
          </div>
        </li>
        <li class="timeline-item timeline-item-right">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2012</div>
            <div class="sub-title">注册用户突破1000万</div>
            <div class="content">
              <p>荣获《Red Herring》杂志社主办评选的：《红鲱鱼》亚洲创新百强企业</p>
            </div>
          </div>
        </li>
        <li class="timeline-item timeline-item-left">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2011</div>
            <div class="sub-title">获启明创投、思伟投资A轮投资</div>
            <div class="content">
              <p>荣获创业邦主办评选的：中国年度创新成长企业100强</p>
              <p>荣获21世纪商业评论、21世纪经济报道主办评选的：21世纪中国最佳商业模式奖</p>
              <p>荣获《互联网周刊》和中国社科院信息化研究中心联合主办颁发的：最佳电商效果营销奖</p>
            </div>
          </div>
        </li>
        <li class="timeline-item timeline-item-right">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2010</div>
            <div class="sub-title">注册用户数突破100万</div>
          </div>
        </li>
        <li class="timeline-item timeline-item-left">
          <div class="timeline-item-head"></div>
          <div class="timeline-item-tail"></div>
          <div class="timeline-item-content">
            <div class="title">2006</div>
            <div class="sub-title">返利网上线</div>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Honour',
  components: {

  },
}
</script>

<style lang="less" scoped>
.honour {
  background: #FFF;
  .banner-wrap{
    img{
      width: 100%;
    }
  }
  .timeline-wrap{
    margin: 0 auto;
    width: 1117px;
    padding-top: 91px;
    padding-bottom: 30px;
    .timeline-alternate{
      list-style: none;
      .timeline-item{
        position: relative;
        margin: 0;
        padding: 0 0 20px;
        font-size: 18px;
        list-style: none;
        .timeline-item-head{
          position: absolute;
          left: 50%;
          margin-left: -7px;
          width: 14px;
          height: 14px;
          background: #D8D8D8;
          border-radius: 50%;
        }
        .timeline-item-tail{
          position: absolute;
          top: 24px;
          left: 50%;
          margin-left: -1px;
          height: calc(100% - 34px);
          border-left: 2px solid #D8D8D8;
        }
        .timeline-item-content{
          position: relative;
          top: -17px;
          word-break: break-word;
          text-align: left;
          .title{
            line-height: 48px;
            font-size: 34px;
            color: #363636;
          }
          .sub-title{
            margin-top: 9px;
            padding-bottom: 12px;
            line-height: 32px;
            font-size: 20px;
            color: #313131;
          }
          .content{
            margin-top: 20px;
            line-height: 32px;
            font-size: 18px;
            color: #8A8A8A;
          }
        }
        &.timeline-item-left{
          .timeline-item-content{
            width: calc(50% - 38px);
          }
        }
        &.timeline-item-right{
          .timeline-item-content{
            left: calc(50% + 52px);
            width: calc(50% - 52px);
          }
        }
      }
    }
  }
}
</style>
