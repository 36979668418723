<template>
    <div id="header">
        <div class="content">
            <div class="icon">
                <router-link to="/home"
                    ><img
                        src="https://static2.51fanli.net/webapp/images/app/corp/common/header_logo.png"
                        alt=""
                /></router-link>
            </div>
            <div class="nav-bars" :class="{ compact: biddingShow }">
                <div
                    class="item"
                    @mouseover="showHomeList"
                    @mouseleave="hideHomeList"
                >
                    <router-link
                        class="item"
                        :class="{ 'router-link-exact-active': isHome }"
                        to="/home"
                        >走进返利</router-link
                    >
                    <ul
                        class="home-list"
                        @click="hideHomeList"
                        v-show="showDetail"
                    >
                        <li>
                            <router-link class="home-list-item" to="/management"
                                >高管团队</router-link
                            >
                        </li>
                        <li>
                            <router-link class="home-list-item" to="/honour"
                                >发展沿革</router-link
                            >
                        </li>
                        <!-- <li><router-link class="home-list-item" to="/partner">加入我们</router-link></li> -->
                    </ul>
                </div>
                <div></div>
                <router-link class="item" to="/business">返利动态</router-link>
                <router-link class="item" to="/responsibility"
                    >企业社会责任</router-link
                >
                <router-link class="item" to="/jobs">成为返利er</router-link>
                <router-link class="item" to="/relation"
                    >投资者关系</router-link
                >
                <router-link v-if="biddingShow" class="item" to="/bidding"
                    >企业采购招标</router-link
                >
            </div>
        </div>
    </div>
</template>

<script>
import sendJsonpRequest from "../../utils/jsonp";

export default {
    name: "Header",
    data: function() {
        return {
            isHome: false,
            showDetail: false,
            biddingShow: false,
        };
    },
    watch: {
        $route: function(to) {
            if (
                ["Management", "Honour", "Partner", "Home", "About"].indexOf(
                    to.name
                ) > -1
            ) {
                this.isHome = true;
            } else {
                this.isHome = false;
            }
        },
    },
    components: {},
    methods: {
        showHomeList() {
            this.showDetail = true;
        },
        hideHomeList() {
            this.showDetail = false;
        },
        isBiddingShow() {
            const that = this;

            sendJsonpRequest({
                url: "https://huodong.fanli.com/hd/notice99990/ajaxIsOpen",
                success: (res) => {
                    if (res?.data?.is_open == 1) {
                        that.biddingShow = true;
                    }
                },
            });
        },
    },
    mounted() {
        if (
            ["Management", "Honour", "Partner", "Home", "About"].indexOf(
                this.$route.name
            ) > -1
        ) {
            this.isHome = true;
        }
        this.isBiddingShow();
    },
};
</script>

<style lang="less">
#header {
    position: absolute;
    top: 0;
    height: 86px;
    width: 100%;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    .content {
        max-width: 100%;
        min-width: 1200px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon {
            display: flex;
            width: 154px;
            height: 64px;
            margin-top: 30px;
            a {
                display: flex;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }
    }
    .nav-bars {
        display: flex;
        align-content: center;
    }
    .item {
        height: 86px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #fff;
        margin-left: 92px;
        font-weight: 500;
        position: relative;
        transition: margin-left 0.3s ease-out;
        &:hover {
            color: #32d03c;
        }
        &.router-link-exact-active,
        &.cur {
            color: #32d03c;
            position: relative;
            &::after {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                content: "";
                height: 3px;
                width: 48px;
                background: #32d03c;
                border-radius: 1.5px;
            }
        }
    }
    .compact .item {
        margin-left: 63.5px;
    }
    .home-list {
        position: absolute;
        width: 112px;
        display: flex;
        flex-direction: column;
        bottom: -80px;
        box-sizing: border-box;
        padding-top: 10px;
        transform: translateX(40%);
        background: #fff;
        box-shadow: 0px 0px 6px #aaaaaa;
        z-index: 1000;
        li {
            display: flex;
            align-content: center;
            justify-content: center;
            height: 30px;
            margin: 6px;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-size: 16px;
                color: #333;
                font-weight: 500;
                &:hover {
                    color: #32d03c;
                }
            }
        }
    }
}
</style>
