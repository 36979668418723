<template>
    <div class="business">
        <div class="banner">
            <img
                src="https://static2.51fanli.net/webapp/images/app/corp/business/business-banner.png"
                alt="business"
            />
        </div>
        <div class="news-sec">
            <div class="news-item" v-for="(item, index) of newsArr" :key="index">
                <a :href="item.link" target="_blank">
                    <img class="news-pic" :src="item.image" alt="" />
                    <div class="news-text">
                        <p class="title">{{ item.title }}</p>
                        <p class="sub-title">{{ item.sub_title }}</p>
                        <p class="time">{{ item.time }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Business',
    data () {
        return {
            newsArr: [
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/1.png',
                    title: '返利科技发布一季报收入增长18.52%…',
                    sub_title: '4月29日晚间，返利科技（600228.SH）发布2021年一季度报告，这是返利科技登陆资本市场后递交的…',
                    time: '2021-04-29 22:22',
                    link: 'https://m.nbd.com.cn/articles/2021-04-29/1727660.html'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/2.png',
                    title: '返利科技举行上市仪式 正式亮相上交…',
                    sub_title: '上证报中国证券网讯（记者 祁豆豆）4月28日，返利科技在上交所举行上市仪式。与IPO上市仪式不同…',
                    time: '2021-04-28 11:16:12',
                    link: 'http://news.cnstock.com/news,bwkx-202104-4695196.htm'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/3.png',
                    title: '返利科技成功上市 瞄准数字消费赛道…',
                    sub_title: '上证报中国证券网讯（记者 祁豆豆）4月28日，返利科技在上交所举行上市仪式。与IPO上市仪式不同…',
                    time: '2021-04-13 18:09',
                    link: 'http://www.time-weekly.com/wap-article/280593'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/4.png',
                    title: '返利科技成功上市 焕然新生后的奋进',
                    sub_title: '在大数据时代，数字经济的快速发展为中国经济前行注入新动力，同时，大数据时代也是一场深刻的新技术…',
                    time: '2021-04-28 22:20:03',
                    link: 'http://www.100ec.cn/detail--6591164.html'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/5.png',
                    title: '支持返利网做公益，请投票！',
                    sub_title: '不知道返利网陪伴了你多久?15年?  7年?  1个月？自返利网2006年成立以来在默默陪伴你的日子里…',
                    time: '2021-03-15',
                    link: 'https://mp.weixin.qq.com/s/YvFgIkSJbSbrG18M9dou6Q'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/6.png',
                    title: '牛年吹来新消费风潮 A股将迎导购行…',
                    sub_title: '中证网讯（记者 傅苏颖）ST昌九2月24日晚间发布公告，公司重大资产重组事项获得中国证监会批复核...',
                    time: '2021-02-24 20:57',
                    link: 'http://www.cs.com.cn/ssgs/gsxw/202102/t20210224_6141271.html'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/7.png',
                    title: '首次开放家居聚合服务 返利网携手国...',
                    sub_title: '在大数据时代，数字经济的快速发展为中国经济前行注入新动力，同时，大数据时代也是一场深刻的新技术…',
                    time: '2020-09-15 13:15',
                    link: 'https://t.cj.sina.com.cn/articles/view/1653603955/628ffe7302000y5cu?from=tech&sudaref=www.baidu.com&display=0&retcode=0'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/8.png',
                    title: '荣登“在线新经济（上海）50强” 返...',
                    sub_title: '不知道返利网陪伴了你多久?15年?  7年?  1个月？\n自返利网2006年成立以来在默默陪伴你的日子里…',
                    time: '2020-08-06 16:45',
                    link: 'https://www.jiemian.com/article/4786689.html'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/9.png',
                    title: '返利网携手苹果推出“Apple教育季”优...',
                    sub_title: '8月4日消息，《电商报》获悉，8月3日-8月7日，返利网携手苹果官网推出“Apple教育季”专场。这是继返...',
                    time: '2020-08-06 16:45',
                    link: 'https://coffee.pmcaff.com/article/13600175_j'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/10.png',
                    title: '携手希思黎、苹果、耐克等品牌商城...',
                    sub_title: '近日，返利网推出“大牌计划”，携手希思黎、碧欧泉、耐克、阿迪达斯、苹果、联想等知名品牌，组团...',
                    time: '2020-07-29 11:34',
                    link: 'https://baijiahao.baidu.com/s?id=1673520628571266336&wfr=spider&for=pc'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/11.png',
                    title: '返利网斩获第11届金鼠标数字营销大赛两项大奖',
                    sub_title: '近日，作为数字营销的风向标，第11届金鼠标数字营销大赛云端颁奖。返利网凭借品效合一的卓越营销实践...',
                    time: '2020-07-24 15:23:25',
                    link: 'http://www.xfrb.com.cn/article/focus/15271664288214.html'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/12.png',
                    title: '从优秀到卓越  返利网荣膺京东618“...',
                    sub_title: '继"逐梦者·流量突出贡献奖"之后，返利网再次荣膺获京东"卓越伙伴奖"殊荣。凭借对京东618重点类目的...',
                    time: '2020-07-13 16:52',
                    link: 'https://t.cj.sina.com.cn/articles/view/2270445964/8754418c00100ofnk?from=tech&subch=internet'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/13.png',
                    title: '返利网获京东联盟618“逐梦者·流量…',
                    sub_title: '日前，凭借618期间的突出流量贡献，返利网获得京东联盟“星耀光点 逐梦起航”的“逐梦者·流量突出贡献...',
                    time: '2020-07-02 19:01',
                    link: 'https://baijiahao.baidu.com/s?id=1671102912129605433&wfr=spider&for=pc'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/14.png',
                    title: '返利网《别叫我"后浪"》：把话筒还...',
                    sub_title: '6月20日，返利网联合中青视讯、每日经济新闻、北京商报、界面新闻、蓝鲸财经、虎啸奖、创业最前线…',
                    time: '2020-06-22 20:19',
                    link: 'https://www.chinaz.com/2020/0622/1149065.shtml'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/15.png',
                    title: '返利网618大促盘点：为用户省钱1.7..',
                    sub_title: '6月19日，返利网发布618大促战报，截至6月18日24:00，累计为用户省钱超过1.7亿。战报中也首次…',
                    time: '2020-06-19 16:18',
                    link: 'https://www.sohu.com/a/402918050_328817'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/16.png',
                    title: '返利网携手唯品会直播带货 击穿全网...',
                    sub_title: '日前，凭借618期间的突出流量贡献，返利网获得京东联盟“星耀光点 逐梦起航”的“逐梦者·流量突出贡献...',
                    time: '2020-06-17 16:47:13',
                    link: 'http://www.cet.com.cn/wzsy/ycxw/2580873.shtml'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/17.png',
                    title: '返利网上线”全网口罩绿色通道”，联...',
                    sub_title: '6月20日，返利网联合中青视讯、每日经济新闻、北京商报、界面新闻、蓝鲸财经、虎啸奖、创业最前线…',
                    time: '2020.01.31 16:56:38',
                    link: 'http://www.techweb.com.cn/it/2020-01-31/2775130.shtml'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/18.png',
                    title: '驰援武汉首批49万只口罩已在途，返...',
                    sub_title: '6月19日，返利网发布618大促战报，截至6月18日24:00，累计为用户省钱超过1.7亿。战报中也首次…',
                    time: '2020-01-25 23:15',
                    link: 'https://baijiahao.baidu.com/s?id=1656713702128693610&wfr=spider&for=pc'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/19.png',
                    title: '剁手族热情不减，双12当天返利网...',
                    sub_title: '日前，凭借618期间的突出流量贡献，返利网获得京东联盟“星耀光点 逐梦起航”的“逐梦者·流量突出贡献...',
                    time: '2020-01-01 00:05',
                    link: 'https://www.sohu.com/a/363991594_425343'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/20.png',
                    title: '返利网战略升级 2亿投入扶持原创内...',
                    sub_title: '6月20日，返利网联合中青视讯、每日经济新闻、北京商报、界面新闻、蓝鲸财经、虎啸奖、创业最前线…',
                    time: '2019-11-22 01:54',
                    link: 'https://www.sohu.com/a/355264821_116778'
                },
                {
                    image: 'https://static2.51fanli.net/webapp/images/app/corp/business/21.png',
                    title: '返利网跻身《2019胡润全球独角兽榜》',
                    sub_title: '6月19日，返利网发布618大促战报，截至6月18日24:00，累计为用户省钱超过1.7亿。战报中也首次…',
                    time: '2019-10-22 15:54:45',
                    link: 'https://finance.ifeng.com/c/7qyevaaBAHe'
                }
            ]
        }
    }
}
</script>

<style lang="less" scoped>
div, img, p {
    box-sizing: border-box;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.business {
    background: #f6f6f6;
    .banner {
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }
    .swiper-box {
        width: 100%;
        padding-top: 80px;
        box-sizing: border-box;
        .title {
            height: 45px;
            line-height: 45px;
            margin-bottom: 23px;
            font-size: 32px;
            text-align: center;
        }
    }
    .news-sec {
        width: 1200px;
        height: auto;
        margin: 0 auto;
        padding-top: 102px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .news-item {
            width: 372px;
            height: 372px;
            margin-bottom: 40px;
            background: #f6f6f6;
            cursor: pointer;
            .news-pic {
                width: 372px;
                height: 224px;
            }
            .news-text {
                width: 372px;
                height: 148px;
                background: #fff;
                padding: 15px 22px 21px;
                position: relative;
                top: -2px;
                p {
                    text-align: left;
                }
                .title {
                    font-size: 18px;
                    color: #333333;
                    margin-bottom: 10px;
                }
                .title:hover {
                    text-decoration: underline;
                }
                .sub-title {
                    font-size: 12px;
                    color: #9E9E9E;
                    line-height: 20px;
                }
                .time {
                    position: absolute;
                    left: 24px;
                    bottom: 20px;
                    font-size: 12px;
                    color: #CCCFD0;
                }
            }
        }
    }
}
</style>
