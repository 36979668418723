<template>
    <div class="relation">
        <div class="banner">
            <img
                src="https://static2.51fanli.net/webapp/images/app/corp/common/banner_relation.jpg"
                alt="jobs"
                class="h100"
            />
        </div>
        <div class="include">
            <iframe
                width="100%"
                height="630px"
                class="pointer_events_none"
                scrolling="no"
                src="https://huodong.fanli.com/hd/notice600228"
            ></iframe>
        </div>
        <p class="subtitle">投资者服务</p>
        <div class="contact">
            <div class="left">
                <p class="item mt-71">投资者服务热线：021-80231198</p>
                <p class="item mt-20">
                    服务时间：交易日 9:30-11:30、13:30-17:00
                </p>
                <p class="item mt-67">投资者服务传真：021-80231199 转 1235</p>
                <p class="item mt-20">
                    服务时间：交易日 9:30-11:30、13:30-17:00
                </p>
            </div>
            <div class="right">
                <p class="item mt-55">
                    投资者服务邮箱：600228@fanli.com、<br />IR@fanli.com
                </p>
                <p class="item mt-20">上市公司媒体关系邮箱：SPR@fanli.com</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Relation',
    components: {

    },
}
</script>

<style lang="less">
.relation {
    padding: 0 0 139px;
    background: #fff;
    overflow-x: hidden;

    .h100 {
        height: 100%;
    }

    .banner {
        img {
            width: 100%;
        }
    }

    .include {
        width: 1200px;
        height: 630px;
        margin: 80px auto 90px;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        overflow: hidden;
    }

    .subtitle {
        line-height: 45px;
        margin: 0 auto;
        font-size: 32px;
        color: #000;
        text-align: center;
    }

    .contact {
        display: flex;
        width: 1200px;
        height: 360px;
        margin: 50px auto 0;
        background: #f5f5f5;
        text-align: left;

        .left {
            flex: 1;
            width: 0;
            border-right: 1px solid #d8d8d8;
            padding: 0 0 0 64px;
            box-sizing: border-box;
        }
        .right {
            flex: 1;
            width: 0;
            width: 0;
            padding: 0 0 0 64px;
            box-sizing: border-box;
        }

        .item {
            line-height: 31px;
            font-size: 22px;
            color: #333;
        }

        .mt-20 {
            margin-top: 20px;
        }

        .mt-50 {
            margin-top: 50px;
        }

        .mt-55 {
            margin-top: 55px;
        }

        .mt-67 {
            margin-top: 67px;
        }

        .mt-71 {
            margin-top: 71px;
        }
    }
}
</style>
