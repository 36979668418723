import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Business from '../views/Business.vue'
import Responsibility from '../views/Responsibility.vue'
import Jobs from '../views/Jobs.vue'
import Relation from '../views/Relation.vue'
import Bidding from '../views/Bidding.vue'
import Management from '../views/Management.vue'
import Honour from '../views/Honour.vue'
import JobsDetail from '../views/jobsDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'Home' }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/management',
    name: 'Management',
    component: Management
  },
  {
    path: '/honour',
    name: 'Honour',
    component: Honour
  },
  {
    path: '/business',
    name: 'Business',
    component: Business
  },
  {
    path: '/responsibility',
    name: 'Responsibility',
    component: Responsibility
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
  },
  {
    path: '/jobs_detail/:id',
    name: 'JobsDetail',
    component: JobsDetail
  },
  {
    path: '/relation',
    name: 'Relation',
    component: Relation
  },
  {
    path: '/bidding',
    name: 'Bidding',
    component: Bidding
  },
]

const router = new VueRouter({
  routes
})

export default router
