<template>
  <div class="responsibility">
    <div class="banner">
      <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/resposibility_banner.jpg" alt="企业社会责任">
    </div>
    <div class="proposition">
      <h2 class="title">公益主张</h2>
      <div class="content">
        <div class="item-box">
          <div class="img-wrap">
            <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/proposition_1.jpg" alt="">
          </div>
          <p class="item-title">每一笔消费都是一次公益</p>
          <p class="text">有消费的地方就有返利<br>有返利的地方就有公益</p>
        </div>
        <div class="item-box">
          <div class="img-wrap">
            <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/proposition_2.jpg" alt="">
          </div>
          <p class="item-title">践行公益0门槛！0距离！</p>
          <p class="text">我们希望利用数字化技术和工具 创新公益模式 有消费的地方就有返利，有返利的地方就有公益</p>
        </div>
      </div>
    </div>
    <div class="track">
      <h2 class="title">公益轨迹</h2>
      <p class="sub-title">15年公益路上不停歇 逾100次公益活动<br>超100000位受益人 荣登“互联网50年50佳公益榜”</p>
      <div class="content">
        <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/track_1.jpg?v=20210816" alt="">
        <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/track_2.jpg" alt="">
        <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/track_3.jpg" alt="">
        <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/track_4.jpg" alt="">
        <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/track_5.jpg" alt="">
        <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/track_6.jpg" alt="">
        <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/track_7.jpg" alt="">
        <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/track_8.jpg" alt="">
        <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/track_9.jpg" alt="">
        <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/track_10.jpg" alt="">
      </div>
    </div>
    <div class="organization">
      <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/organization.jpg" alt="">
    </div>
    <h2 class="title">疫情行动</h2>
    <div class="action">
      <div class="content">
        <div class="left">
          <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/action_11.png" alt="">
          <img src="https://static2.51fanli.net/webapp/images/app/corp/responsibility/action_21.png" alt="">
        </div>
        <div class="right">
          <p class="title">阻击疫情，携手2亿用户，亿心一意，共克时艰</p>
          <p class="sub-title">第一时间提供“汇集全网口罩的绿色通道”。</p>
          <p>2020年1月21日，返利网内部成立了专项疫情应急支援小组。2020年1月30日，返利网全网最早上线“全网口罩绿色通道”，第一时间打通医疗物资供给线，为武汉及湖北重点疫区医护人员提供保障。</p>
          <p class="sub-title">打通医疗物资供给线，为武汉及湖北重点疫区医护人员提供保障。</p>
          <p>第一时间联系上了湖北省中医院、武汉第三医院、武汉第五医院等定点医疗机构，并安排专人从国内外寻找符合一线医疗需要的急缺物资，紧密跟进多个供应商加量生产，补充供应，驰援武汉。</p>
          <p>2020年1月24日，返利网捐赠的首批医护救援物资成功发往武汉。49万只口罩、4万余副医用手套、1300个护目镜、1万双医用鞋套等，包括了从日本、德国等海外采购的部分物资。</p>
          <p class="sub-title">上线“抗击疫情驰援武汉”爱心募捐通道</p>
          <p>募捐所得善款对接湖北省抗疫救灾前线，用于采购医用口罩、防护面罩、防护服、护目镜、测温仪等前线急缺医护救援物资。</p>
          <p class="sub-title">上线“心系武汉”抗疫防控专题</p>
          <p>汇聚了物资购买资讯、疫情动态、紧急物资（捐赠）渠道、科学抗病毒措施教育等。</p>
          <p>聚合了全网口罩库存监控、爱心物资捐赠、防疫指南及防护必备清单、全国疫情实时数据、同行交通查询、谣言粉碎等信息。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Responsibility',
  components: {

  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.h100 {
  height: 100%;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.responsibility {
  width: 100%;
  background: #FFF;
  .banner {
    width: 100%;
    img{
      width: 100%;
    }
  }
  .proposition{
    width: 100%;
    padding: 78px 0 27px;
    text-align: center;
    .title{
      line-height: 34px;
      font-size: 34px;
      color: #111;
    }
    .content{
      width: 100%;
      margin-top: 64px;
      display: flex;
      justify-content: center;
      .item-box{
        width: 580px;
        height: 740px;
        font-size: 20px;
        color: #7B7B7B;
        line-height: 32px;
        background: #FFFFFF;
        box-shadow: 0px 6px 20px 0px rgba(0,0,0,0.09);
        &:nth-child(2){
          margin-left: 40px;
        }
        .img-wrap{
          height: 459px;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
        .item-title{
          margin: 59px 0 35px;
          font-size: 34px;
          color: #111;
          line-height: 32px;
        }
        .text{
          padding: 0 55px;
        }
      }
    }
  }
  .track{
    width: 100%;
    padding: 78px 0 27px;
    text-align: center;
    .title{
      line-height: 34px;
      font-size: 34px;
      color: #111;
    }
    .sub-title{
      margin-top: 30px;
      line-height: 40px;
      font-size: 28px;
      color: #646464;
    }
    .content{
      margin: 89px auto 0;
      width: 1201px;
      display: flex;
      flex-wrap: wrap;
      img{
        margin: 0 16px 16px 0;
        &:nth-child(2){
          margin-right: 0;
        }
        &:nth-child(4){
          margin-right: 0;
        }
        &:nth-child(7){
          margin-right: 0;
        }
        &:nth-child(10){
          margin-right: 0;
        }
      }
    }
  }
  .organization{
    width: 100%;
    img{
      width: 100%;
    }
  }
  .title{
    font-weight: bold;
    text-align: center;
    font-size: 34px;
    color: #111;
    margin-top: 89px;
    line-height: 34px;
  }
  .action{
    box-sizing: border-box;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .content{
      margin: 60px auto 0;
      width: 1443px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 138px;
      .left{
        width: 542px;
        img{
          width: 100%;
          &:nth-child(2){
            margin-top: 24px;
          }
        }
      }
      .right{
        width: 626px;
        text-align: left;
        p{
          font-size: 20px;
          color: #393939;
          line-height: 32px;
        }
        .title{
          margin-top: 0;
          text-align: left;
          line-height: 37px;
          font-size: 26px;
          color: #000;
        }
        .sub-title{
          margin: 24px 0;
          line-height: 30px;
          font-size: 22px;
          color: #393939;
        }
      }
    }
  }
}
</style>
