<template>
  <div class="jobs-index">
    <div class="banner">
        <img
            src="https://static2.51fanli.net/webapp/images/app/corp/home/jobs3-banner.png"
            alt="business"
        />
    </div>
    <div class="company-introduce">
        <p>返利科技是一个扁平多元的培训与学习型公司。</p>
        <p>我们齐头并进，坦诚互助；我们鼓励持续价值创造，永远为高绩效高成长喝彩。</p>
        <p>如果你认为我们可以一起并肩向前、迎接挑战，现在就通过邮箱：<a href="mailto:zhaopin@fanli.com">zhaopin@fanli.com</a>联系我们吧。
        </p>
        <p>等你好久了，下一个返利er</p>
    </div>
    <!-- 职位列表 -->
    <div class="position-bkc">
        <div class="position-list">
            <h4 class="list-title">我们希望这些职位中有你的身影</h4>
            <div class="list">
            <router-link
                class="list-item"
                :to="'/jobs_detail/' + (index + 1)"
                v-for="(item, index) in positionList"
                :key="index"
            >
                <img :src="item.img" class="item-img" />
                <p class="name">{{ item.name }}</p>
                <p class="view" v-if="index != positionList.length - 1">查看岗位</p>
            </router-link>
            </div>
        </div>
    </div>
    <!-- 图片展示 -->
    <div class="img-list-bkc">
        <div class="img-item" v-for="(item, index) in companyImgs" :key="index">
            <h4 class="item-title">{{item.title}}</h4>
            <p class="item-explain" v-if="item.explain">{{item.explain}}</p>
            <div class="item-show">
                <img :src="Itm" class="show-img" v-for="(Itm, Idx) in item.imgs" :key="Idx">
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'Jobs',
    data () {
        return {
            positionList: [
                {
                img: "https://static2.51fanli.net/webapp/images/app/corp/home/join_technology.png",
                name: "研发",
                },
                {
                img: "https://static2.51fanli.net/webapp/images/app/corp/home/join_product.png",
                name: "产品",
                },
                {
                img: "https://static2.51fanli.net/webapp/images/app/corp/home/join_operate.png",
                name: "运营",
                },
                {
                img: "https://static2.51fanli.net/webapp/images/app/corp/home/join_design.png",
                name: "设计",
                },
                {
                img: "https://static2.51fanli.net/webapp/images/app/corp/home/join_market.png",
                name: "市场",
                },
                {
                img: "https://static2.51fanli.net/webapp/images/app/corp/home/join_sale.png",
                name: "销售",
                },
                {
                img: "https://static2.51fanli.net/webapp/images/app/corp/home/join_support.png",
                name: "职能/支持",
                },
                {
                img: "https://static2.51fanli.net/webapp/images/app/corp/home/join_broadcast.png",
                name: "直播",
                },
                {
                img: "https://static2.51fanli.net/webapp/images/app/corp/home/join_more.png",
                name: "更多职位",
                },
            ],
            companyImgs: [
                {
                    title: '办公环境', 
                    explain: '', 
                    imgs: ['https://static2.51fanli.net/webapp/images/app/corp/home/office_env1.png', 'https://static2.51fanli.net/webapp/images/app/corp/home/office_env2.png', 'https://static2.51fanli.net/webapp/images/app/corp/home/office_env3.png']
                },
                {
                    title: '福利待遇', 
                    explain: '假日福利、生日福利外，应节气为每一位返利er提供特别的节庆礼品\n不定期举办各类交流活动', 
                    imgs: ['https://static2.51fanli.net/webapp/images/app/corp/home/fringe_benefits1.png', 'https://static2.51fanli.net/webapp/images/app/corp/home/fringe_benefits2.png', 'https://static2.51fanli.net/webapp/images/app/corp/home/fringe_benefits3.png']
                },
                {
                    title: '学习成长', 
                    explain: '团建活动、读书会、跨部门信息交流会，专业培训，行业信分享会等\n为每一位返利er提供多元化的丰富学习渠道，共同成长', 
                    imgs: ['https://static2.51fanli.net/webapp/images/app/corp/home/learning1.png', 'https://static2.51fanli.net/webapp/images/app/corp/home/learning2.png', 'https://static2.51fanli.net/webapp/images/app/corp/home/learning3.png']
                },
            ],
        }
    },
    components: {

    },
}
</script>

<style lang="less" scoped>
.jobs-index {
    .banner {
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }
    .company-introduce {
        width: 1200px;
        display: inline-block;
        margin: 0 auto;
        background: #ffffff;
        padding-top: 115px;
        padding-bottom: 144px;
        text-align: left;

        p {
        font-size: 18px;
        color: #8a8a8a;
        line-height: 32px;
        }

        a {
        font-size: 18px;
        color: #32d23c;
        line-height: 32px;
        }
    }

    .position-bkc {
        background: #f9f9fa;
    }

    .position-list {
        display: inline-block;
        margin: 0 auto;
        padding-top: 87px;
        padding-bottom: 120px;

        .list-title {
        text-align: left;
        font-size: 34px;
        color: #363636;
        }

        .list {
        width: 1202px;
        display: flex;
        flex-wrap: wrap;

        .list-item {
            margin-top: 65px;
            width: 252px;
            display: block;

            .item-img {
            width: 180px;
            border-radius: 252px;
            }

            .name {
                margin-top: 20px;
                text-align: center;
                font-size: 28px;
                color: #000000;
            }

            .view {
                margin-top: 13px;
                text-align: center;
                font-size: 20px;
                color: #939393;
                cursor: pointer;
            }
        }

        .list-item:nth-of-type(3n + 2) {
            margin-left: 215px;
            margin-right: 230px;
        }
        }
    }

    .img-list-bkc {
        background: #ffffff;
        padding-top: 36px;
        width: 100%;
        padding-bottom: 251px;

        .img-item {
            display: inline-block;
            margin: 0 auto;
            margin-top: 113px;
            width: 1200px;
            text-align: left;

            .item-title {
                font-size: 34px;
                color: #363636;
                margin-bottom: 20px;
            }

            .item-explain {
                margin-bottom: 20px;
                font-size: 18px;
                color: #8a8a8a;
                line-height: 32px;
            }

            .item-show {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .show-img {
                    margin-top: 20px;
                    width: 588px;
                }

                .show-img:nth-of-type(1) {
                    margin-top: 0;
                    width: 100%;
                }
            }
        }
    }
}
</style>
