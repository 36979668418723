<template>
  <div class="job-detail">
    <div class="banner">
      <img
          src="https://static2.51fanli.net/webapp/images/app/corp/home/jobs2-banner.png"
          alt="business"
      />
    </div>
    <div class="choose-sec">
      <div class="rdo sel-item" v-for="(item, index) of radioArr" :key="index">
        <input v-model="chooseIndex" :id="index" type="radio" :value="index">
        <label class="sel-text" :for="index">{{ item }}</label>
      </div>
    </div>
    <div v-for="(item, index) of jobArr" :key="index">
      <div v-if="chooseIndex == item.type" class="job-item" :class="[item.title=='' ? 'mb400' : '']">
        <div class="job-title">{{ item.title }}</div>
        <div class="keywords-wrap">
          <div class="keywords" v-for="(itm, idx) of item.keywords" :key="idx">{{ itm }}</div>
        </div>
        <div class="content-title" v-if="item.desc">岗位描述：</div>
        <div class="content-value" v-html="item.desc"></div>
        <div class="content-title" v-if="item.skill">任职要求：</div>
        <div class="content-value" v-html="item.skill"></div>
        <div v-if="item.title==''" class="job-item no-job">岗位持续更新中，敬请期待</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'JobsDetail',
    data () {
      return {
        chooseIndex: 0,
        radioArr: [ '研发', '产品', '运营', '设计', '市场', '销售', '职能/支持', '直播' ],
        jobArr: [
          {
            type: '0',
            title: '',
            keywords: [],
            desc: '',
            skill: ''
          },
          {
            type: '1',
            title: '高级产品经理',
            keywords: [ '上海', '产品', '社招' ],
            desc: '1. 负责用户增长，包含拉新、转化、留存等产品设计工作，协同相关资源实现业务目标。同时，站内的栏目规划，日常营销的产品规划。</br>2. 参与搭建产品数据监控与分析体系，结合用户研究情况，调整迭代方向与策略；</br>3. 运用多种分析和评估手段，分析用户行为，挖掘用户需求，并将之转化为可行的产品方案；</br>4、支撑运营活动需求，与技术、运营、商务等团队紧密配合，高效执行和推进，达成产品目标；</br>5、熟悉各种拉新套路玩法。',
            skill: '1. 大学本科以上学历，5年及以上互联网产品经理相关的工作经验；</br>2.有用户增长growth hacking经验，对流量获取、流量分发和转化有独特的见解优先；对分享裂变MGM等增长方式有操盘的经验；</br>3. 熟悉导购类工具产品或电商产品经验优先；对用户体验的优化，有自己独到的理解和产品思维；</br>4. 对数据敏感，有独立完整的数据分析经验，能快速发掘业务和数据的联系，熟练掌握SQL者加分；</br>5. 有较强的综合决策能力，能在错综复杂的环境中，找到正确的角度&方向，做出正确的决策；</br>6. 有较强的沟通协作及项目管理能力，善于跨团队合作，具备积极向上的创业心态，良好的职业素质；</br>7. 强烈的使命和自我驱动，抗压能力强'
          },
          {
            type: '2',
            title: '直播运营',
            keywords: [ '上海', '主播', '社招' ],
            desc: '1、 负责公司抖音直播间的运营和管理，对直播整场流程能顺利把控；</br>2、 负责对接外部达人，并对达人合作数据进行监控，对整体转化数据分析，与第三方接洽复盘，甄别合作可靠性，确保执行效果符合预期；</br>3、 甄选直播带货达人，建立优质媒体、红人、MCN机构资料库，针对高转化服务商进行长期的关系维护以及档案建设管理；</br>4、 整理和收集同行业的直播销售数据，建立较新的信息资料库，以便为确定新的优质直播机构和主播提供依据和数据支持；',
            skill: '1、本科及以上学历，2年以上直播平台带货管理经验，抖音平台优先；</br>2、具备实操经验丰富，擅长项目管理，善于沟通，团队合作意识强；</br>3、对数据有系统和深刻的认知，能快速从数据中寻找问题，能用数据驱动团队的策略和方向。'
          },
          {
            type: '3',
            title: '',
            keywords: [],
            desc: '',
            skill: ''
          },
          {
            type: '4',
            title: '',
            keywords: [],
            desc: '',
            skill: ''
          },
          {
            type: '5',
            title: '广告销售经理',
            keywords: [ '上海', '销售', '社招' ],
            desc: '1. 拓展新客户，进行商务洽谈，维护客户关系，完成销售目</br>2. 对客户合作需求有判断力，主动对需求进行引导转化，把控商务进度；</br>3. 可以根据不同客户类型提供行之有效的广告营销解决思路，清晰地向策划传递客户需求；</br>4. 能够有条理地将广告上线前的准备工作安排至项目支持端，积极主动关注广告上线后的运营效果；</br>5. 从广告商业产品的研发、包装、市场定位等角度深入了解资源属性，熟悉自身平台和用户；</br>6. 结合客户的品效合一需求，统筹整合公司平台内容资源，为完善商业生态体系提供建议。',
            skill: '1. 本科以上学历，2年以上互联网媒体广告销售经验；</br>2. 有日化、美妆、食品、数码、家电、家装等行业客户基础；</br>3. 具备较强的拓展能力、学习能力和团队协作能力，有事业心、责任感和荣誉感；能承受较强工作压力，具备强大进取；</br>4. 谈吐得当，形象气质佳；</br>5. 有品效营销或内容化营销相关经历者优先'
          },
          {
            type: '5',
            title: '广告客户经理',
            keywords: [ '上海', '销售', '社招' ],
            desc: '1. 与前端销售和策划沟通，深刻理解客户合作目标；</br>2. 与客户进行充分沟通，准确、及时、有效地为客户提供专业的项目执行规划；</br>3. 高效地领导项目组人员完成项目运作，掌握工作进度和方向；</br>4. 监控活动效果，及时根据客户要求和运营情况变化提供解决方案，对项目结果负责；</br>5. 统筹各支持部门工作，协调关系，确保为客户提供顺畅的服务；</br>6. 协助销售维护客户关系，将客户的直观感受和效果反馈及时传达给销售。',
            skill: '1. 本科以上学历，2年以上互联网媒体项目管理经验；</br>2. 执行过日化、美妆、食品、数码、家电、家装等行业营销项目，独立完成或参与完成年度项目执行；</br>3. 高效执行能力、团队协作能力并极具沟通耐心，有事业心、责任感和荣誉感；能承受较强工作压力，具备强大进取心；</br>4. 有品效营销或内容化营销相关经历者优先，有大型项目/年度项目相关经验者优先。'
          },
          {
            type: '6',
            title: '',
            keywords: [],
            desc: '',
            skill: ''
          },
          {
            type: '7',
            title: '主播',
            keywords: [ '上海', '主播', '社招' ],
            desc: '1、依照公司安排在平台进行直播，并参与线上&线下粉丝维护的工作；</br>2、独立完成直播内容，引导观众关注直播间，促进粉丝增长，提高粉丝粘度，调动粉丝情绪，增加粉丝的活跃度，确保直播间热度；</br>3、向客户种草产品、展示货品、与粉丝互动（包括回答客户问题，产品展示、优惠券发放、引导下单等）；</br>4、配合公司直播需求努力提高才艺与直播技巧、提炼产品卖点，并向粉丝推荐商品&完成订单转化 ；',
            skill: '1、对快消和服装行业长期关注，并有一定的相关知识积累；</br>2、语言表达能力流畅，活泼外向，镜头造型感强，善于调动气氛；</br>3、形象气质佳、会化妆、爱打扮；</br>4、熟悉各类直播新媒体平台，了解最新的时尚动态；</br>5、有商品导购经验者优先，普通话标准；</br>6、喜欢直播行业，具有直播经验、自带粉丝者优先。'
          }
        ]
      }
    },
    mounted () {
      window.scrollTo(0, 0)
      this.chooseIndex = this.$route.params.id - 1
    },
    watch: {
      chooseIndex (newVal) {
        this.chooseIndex = newVal
        console.log(this.chooseIndex)
      }
    }
}
</script>

<style lang="less" scoped>
div, img, p {
    box-sizing: border-box;
}
.h100 {
    height: 100%;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.job-detail {
  background: #f6f6f6;
  overflow-x: hidden;
  .banner {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .choose-sec {
    margin: 50px auto 70px;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .sel-item {
      .sel-text {
        font-size: 20px;
        color: #333333;
        font-weight: bold;
      }
    }
  }
  .job-item {
    margin: 0 auto 20px;
    width: 1200px;
    text-align: left;
    padding: 34px 43px;
    background: #fff;
    .job-title {
      font-size: 32px;
      color: #333333;
    }
    .keywords-wrap {
      margin: 16px 0 22px;
      display: flex;
      .keywords {
        margin-right: 20px;
        font-size: 18px;
        color: #8A8A8A;
      }
    }
    .content-title {
      font-size: 22px;
      color: #333333;
      margin: 22px 0;
    }
    .content-value {
      font-size: 18px;
      color: #8A8A8A;
      line-height: 32px;
    }
  }
  .mb400 {
    margin-bottom: 400px;
  }
  .no-job {
    font-size: 32px;
    color: #A1A1A1;
    line-height: 32px;
    text-align: center;
    background: none;
  }
}
input[type="radio"] + label::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border: 1px solid #979797;
    margin-right: 10px;
    box-sizing: border-box;
}
input[type="radio"]:checked + label::before {
    background-image: url('https://static2.51fanli.net/webapp/images/app/corp/sub-jobs/choose-icon.png');
    background-size: contain;
    // background-clip: content-box;
    box-sizing: border-box;
    border: 1px solid #31C539;
}
input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}
</style>
