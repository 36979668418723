<template>
    <div class="bidding">
        <div class="banner">
            <img
                src="https://static2.51fanli.net/webapp/images/app/corp/bidding/bidding_banner.jpg"
                alt="bidding"
                class="h100"
            />
        </div>
        <div class="include">
            <iframe
                width="100%"
                height="630px"
                class="pointer_events_none"
                scrolling="no"
                src="https://huodong.fanli.com/hd/notice99990"
            ></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: "Bidding",
    components: {},
};
</script>

<style lang="less">
.bidding {
    padding: 0 0 30px;
    background: #fff;
    overflow-x: hidden;

    .h100 {
        height: 100%;
    }

    .banner {
        img {
            width: 100%;
        }
    }

    .include {
        width: 1200px;
        height: 630px;
        margin: 80px auto 50px;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        overflow: hidden;
    }
}
</style>
